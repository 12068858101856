import { useDispatch, useSelector } from "react-redux"
import CustomAvatar from "../../Avatar"

import * as chatService from '../../../services/chat'

import { selectLead } from "../../../action/leads"
import { setContact } from "../../../action/newSolicitations"

import './styles.css'

const PaperLead = ({lead, active}) => {
    const dispatch = useDispatch()
    const messages = useSelector(state => state.lead.messages)

    const handleSelectLead = () => {
        dispatch(selectLead(lead))
        dispatch(setContact({
            uuid: lead.uuid,
            cellphone: lead.cellphone
        }))
        chatService.readMessages(lead)
    }

    return (
        <button onClick={handleSelectLead} className={`paper-leads-container ${active && "active"}`}>
            <div className="paper-leads-avatar-content">
                {lead.photo ? (
                    <CustomAvatar 
                        kind="photo"
                        src={lead.photo}
                        size={36}
                    />
                ):(
                    <CustomAvatar 
                        kind="text"
                        label={lead.name ? lead.name : "Não Informado"} 
                        size={36}
                    />
                )}
                
                <div className="paper-leads-info">
                    <strong>{lead.name ? lead.name : "Não Informado"}</strong>
                    <span style={{color: active ? "white" : "#A3A3A3"}}>{lead.lastMessage}</span>
                </div>
            </div>
        </button>
    )
}
export default PaperLead