import MecanicoIcon from '../../../assets/icon/small-mecanico.png'
import BateriaIcon from '../../../assets/icon/small-bateria.png'
import ChaveiroIcon from '../../../assets/icon/small-chaveiro.png'
import CombustivelIcon from '../../../assets/icon/small-combustivel.png'
import PneusIcon from '../../../assets/icon/small-pneus.png'
import ReboqueIcon from '../../../assets/icon/small-reboque.png'

import './styles.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectSolicitation } from '../../../action/solicitations'
import Button from '../../Button'
import { useState } from 'react'
import PaperProviderDense from '../Provider/Dense/Index'
import InputText from '../../Input/Text'
import * as solicitationService from '../../../services/solicitations'

const PaperSolicitation = ({
    solicitation
}) => {
    const dispatch = useDispatch()

    const providerSelected = useSelector(state => state.providers.selected)
    const providers = useSelector(state => Object.values(state.providers.list))
    const solicitationSelected = useSelector(state => state.solicitations.selected)

    const [redirect, setRedirect] = useState(false)
    const [isScheduled, setIsScheduled] = useState()
    const [loadingButotn, setLoadingButton] = useState(false)
    const [solicitationPrice, setSolicitationPrice] = useState()
    const [solicitationFee, setSolicitationFee] = useState()
    const [calculatingSolicitationPrice, setCalculatingSolicitationPrice] = useState(false)

    const [forms, setForms] = useState({
        paymentPlace: "",
        paymentMethod: "",
        price: 0
    })
    
    const paymentPlace = [
        {id: "onsite", label: "No local"},
        {id: "online", label: "Online"},
    ]

    const paymentMethods = [
        {id: "pix", label: "Pix"},
        {id: "credit_card", label: "Crédito"},
    ]

    const isScheduledOption = [
        {id: true, label: "Sim"},
        {id: false, label: "Não"},
    ]

    const stages = {
        starting: "Iniciando",
        lookingForProvider: "Procurando prestador",
        in_progress: "Em deslocamento",
    }

    const servicesIcon = {
        "mecanico": MecanicoIcon,
        "bateria": BateriaIcon,
        "chaveiro": ChaveiroIcon,
        "combustivel": CombustivelIcon,
        "pneu": PneusIcon,
        "reboque": ReboqueIcon
    }

    const printAddress = () => {
        return `${solicitation.current_location.street}, ${solicitation.current_location.streetNumber}, ${solicitation.current_location.district}`
    }

    const printState = () => {
        return `${solicitation.current_location.city} - ${solicitation.current_location.state}`
    }

    const printPrice = (price) => {
        return Number(price).toLocaleString("pt-BR", {style: "currency", currency: "BRL"})
    }

    const handleChangeForms = (column, value) => {
        setForms({
            ...forms,
            [column]: value
        })
    }

    const handleCalculateSolicitationPrice = () => {
        setCalculatingSolicitationPrice(true)
        if (solicitationPrice && solicitationFee) {
            const priceProvider = Number(solicitationPrice.replace(',', '.'))
            const fee = Number(solicitationFee.replace(',', '.'))
    
            const price = priceProvider + fee
    
            setForms({
                ...forms,
                price: Number(price.toFixed(2)),
                fee: Number((price - priceProvider).toFixed(2))
            })
        }
        setCalculatingSolicitationPrice(false)
    }

    const handleRedirectSolicitation = async () => {
        setLoadingButton(true)
        const response = await solicitationService.redirectSolicitation({
            isScheduled: isScheduled,
            provider: providerSelected,
            solicitation: {
                uuid: solicitation.uuid,
                ...forms
            },
        })
        
        if (response) {
            window.location.href = '/solicitacoes'
        }
        setLoadingButton(false)
    }

    return (
        <button 
            className={`paper-solicitation-container ${solicitation.uuid === solicitationSelected.uuid ? "active" : ""}`} 
            onClick={() => dispatch(selectSolicitation(solicitation))}
        >
            <div className='paper-solicitation-status-content'>
                <img src={servicesIcon[solicitation.service]}/>
                <span>{stages[solicitation.stage]}</span>
            </div>
            <div className='paper-solicitation-summary'>
                <span>{printAddress()}</span>
                <span>{printState()}</span>
                <span>{solicitation.vehicle}</span>
                <span>{solicitation.report}</span>
            </div>
            {redirect ? (
                <div>
                    <div className='paper-solicitation-redicrect-hr'/>
                    <div>
                        <span className='paper-solicitation-redicrect-title'>Redirecionar serviço</span>
                        <div className='paper-solicitation-redicrect-content'>
                            <span className='paper-solicitation-redicrect-subtitle'>Prestadores</span>
                            {providers.length > 0 ? (
                                <div className='paper-solicitation-redicrect-providers-grid'>
                                    {providers.map((provider, index) => {
                                        return (
                                            <PaperProviderDense key={index} provider={provider} />
                                        )
                                    })}
                                </div>
                            ) : (
                                <span>Nenhum prestador online</span>
                            )}
                            {Object.keys(providerSelected).length > 0 &&
                                <div className='paper-solicitation-redicrect-forms-content'>
                                    <div className='paper-solicitation-redicrect-forms-item'>
                                        <span>Onde irá realizar o pagamento:</span>
                                        <div className='paper-solicitation-redicrect-forms-button-group'>
                                            {paymentPlace.map((paymentPlace) => {
                                                return (
                                                    <button 
                                                        key={paymentPlace.id}
                                                        className={`new-value-button-button ${forms.paymentPlace === paymentPlace.id && "active"}`}
                                                        onClick={() => handleChangeForms("paymentPlace", paymentPlace.id)}
                                                    >
                                                        {paymentPlace.label}
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='paper-solicitation-redicrect-forms-item'>
                                        <span>Forma de pagamento:</span>
                                        <div className='paper-solicitation-redicrect-forms-button-group'>
                                            {paymentMethods.map((paymentMethod) => {
                                                return (
                                                    <button 
                                                        key={paymentMethod.id}
                                                        className={`new-value-button-button ${forms.paymentMethod === paymentMethod.id && "active"}`}
                                                        onClick={() => handleChangeForms("paymentMethod", paymentMethod.id)}
                                                    >
                                                        {paymentMethod.label}
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='paper-solicitation-redicrect-forms-item'>
                                        <span>O serviço está agendado?</span>
                                        <div className='paper-solicitation-redicrect-forms-button-group'>
                                            {isScheduledOption.map((option) => {
                                                return (
                                                    <button 
                                                        key={option.id}
                                                        className={`new-value-button-button ${isScheduled === option.id && "active"}`}
                                                        onClick={() => setIsScheduled(option.id)}
                                                    >
                                                        {option.label}
                                                    </button>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className='paper-solicitation-redicrect-forms-item'>
                                        <span>Valor do prestador:</span>
                                        <InputText 
                                            label="Qual valor negociado com o prestador?"
                                            value={solicitationPrice}
                                            onChange={(event) => setSolicitationPrice(event.target.value)}
                                            onBlur={handleCalculateSolicitationPrice}
                                            style={{ marginTop: 8, marginBottom: 8, width: "100%" }}
                                        />
                                        <span>Taxa:</span>
                                        <InputText 
                                            label="Qual valor da taxa?"
                                            value={solicitationFee}
                                            onChange={(event) => setSolicitationFee(event.target.value)}
                                            onBlur={handleCalculateSolicitationPrice}
                                            style={{ marginTop: 8, marginBottom: 3, width: "100%" }}
                                        />
                                        <span>
                                            {forms.price > 0 ? (
                                                <span>{`Valor final do serviço ${printPrice(forms.price)}`}</span>
                                            ) : calculatingSolicitationPrice && (
                                                <span>Calculando...</span>
                                            )}
                                        </span>
                                    </div>
                                    <div className='paper-solicitation-redicrect-button-group'>
                                        <Button 
                                            label="Redirecionar"
                                            disabled={forms.price === 0}
                                            loading={loadingButotn}
                                            onClick={handleRedirectSolicitation}
                                            style={{width: "100%"}}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            ):(
                <div className='paper-solicitation-button-content'>
                    <Button 
                        label="Redirecionar"
                        onClick={() => setRedirect(!redirect)}
                        style={{width: "100%"}}
                    />
                </div>
            )}
        </button>
    )
}

export default PaperSolicitation