import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { CircularProgress, IconButton } from "@mui/material"
import SendIcon from '@mui/icons-material/Send'

import PaperMessage from "../../../../components/Paper/Message"
import Button from "../../../../components/Button"

import * as chatService from '../../../../services/chat'

import '../styles.css'
import { openModal } from "../../../../action/modal"
import PredefinedMessagesMenu from "../../../../components/Menus/PredefinedMessages";
import CustomAvatar from "../../../../components/Avatar"
import { insertMessage, loadMessages, selectLead } from "../../../../action/leads"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Chat = ({device}) => {
    const dispatch = useDispatch()
    const messagesEndRef = useRef(null)

    const messages = useSelector(state => state.lead.messages)
    const leadSelected = useSelector(state => state.lead.selected)

    const [newMessage, setNewMessage] = useState("")
    const [loadingSendMessage, setLodingSendMessage] = useState(false)

    const statusLabel = {
        UNAVAILABLE: "Offline",
        AVAILABLE: "Online",
        COMPOSING: "Digitando...",
        RECORDING: "Gravando audio...",
        PAUSED: "Online"   
    }

    const scrollToBottom = (behavior = "smooth") => {
        messagesEndRef.current?.scrollIntoView({ behavior, block: "nearest" });
    };

    const sendMessage = async () => {
        setLodingSendMessage(true)
        if (newMessage && newMessage !== "") {
            const response = await chatService.sendMessage({
                message: newMessage,
                lead: {
                    ...leadSelected,
                    lastMessage: newMessage
                }
            })

            if (response) {
                dispatch(insertMessage(response))
    
                setNewMessage("")
            }
        }
        setLodingSendMessage(false)
    }

    useEffect(() => {
        (async () => {
            if (Object.keys(leadSelected).length > 0) {
                const response = await chatService.loadMessages(leadSelected.uuid)

                if (response) {
                    dispatch(loadMessages(response))
                }
            }
        })()
    }, [leadSelected])

    useEffect(() => {scrollToBottom("smooth")}, [messages])

    return (
        <div className="leads-messages-content">
            <div className="leads-messages-header">
                <div className="leads-messages-header-avatar">
                    {device === "mobile" && 
                        <IconButton onClick={() => dispatch(selectLead({}))}>
                            <ArrowBackIcon style={{color: "#009CF5"}} fontSize="inherit"/>
                        </IconButton>
                    }
                    {leadSelected.photo ? (
                        <CustomAvatar 
                            kind="photo"
                            src={leadSelected.photo}
                            size={36}
                        />
                    ):(
                        <CustomAvatar 
                            kind="text"
                            label={leadSelected.name ? leadSelected.name : "Não Informado"} 
                            size={36}
                        />
                    )}
                    <div className='leads-chat-header-content'>
                        <h1>{leadSelected.name}</h1>
                        <span>{leadSelected.status ? statusLabel[leadSelected.status] : "Offline"}</span>
                    </div>
                </div>
                <div>
                    <Button 
                        kind="secondary"
                        label={device !== "mobile" ? "Finalizar atendimento":"Finalizar"}
                        onClick={() => dispatch(openModal({
                            modal: "finishContact",
                            value: true
                        }))}
                    />
                </div>
            </div>
            <div className="leads-messages-body">
                {messages.filter(item => item.uuidUser === leadSelected.uuid).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((item, index) => {
                    return (
                        <PaperMessage 
                            key={index}
                            message={item}
                        />
                    )
                })}
                <div ref={messagesEndRef} />
            </div>
            <div className="leads-messages-footer">
                <PredefinedMessagesMenu 
                    lead={leadSelected}
                    onSelected={(option) => setNewMessage(option.value)}
                />
                <textarea 
                    placeholder="Digite..."
                    value={newMessage}
                    onChange={(event) => setNewMessage(event.target.value)}
                />
                <IconButton disabled={loadingSendMessage} onClick={sendMessage}>
                    {loadingSendMessage ? (<CircularProgress size={"1.5rem"}/>) : (<SendIcon style={{color: "#009CF5"}} fontSize="inherit"/>)}
                </IconButton>
            </div>
        </div>
    )
}

export default Chat