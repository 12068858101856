import api from '../services/api';

export const createPartner = async (partner) => {
    try {
        const response = await api.post('/partners', partner)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("createPartner", error)
    }
}

export const updatePartner = async (partner) => {
    try {
        const response = await api.put('/partners', partner)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("updatePartner", error)
    }
}

export const deletePartner = async (partner) => {
    try {
        const response = await api.delete('/partners', {data: partner})
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("deletePartner", error)
    }
}

export const getAllPartners = async () => {
    try {
        const response = await api.get('/partners/all')
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("getAllPartners", error)
    }
}

export const updateCreditCard = async (newCreditCard) => {
    try {
        const response = await api.post('/partners/updateCreditCard', newCreditCard)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("updateCreditCard", error)
    }
}

export const updateAmount = async (newAmount) => {
    try {
        const response = await api.post('/partners/updateAmount', newAmount)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("updateCreditCard", error)
    }
}

export const addNewUser = async (newUser) => {
    try {
        const response = await api.post('/partners/users', newUser)
        if (response.status === 200) return response.data
        return false
    } catch (error) {
        console.log("addNewUser", error)
    }
}

export const deleteUser = async (user) => {
    try {
        const response = await api.delete('/partners/users', {data: user})
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("deletePartner", error)
    }
}

export const updateUser = async (user) => {
    try {
        const response = await api.put('/partners/users', user)
        if (response.status === 200) return true
        return false
    } catch (error) {
        console.log("updateUser", error)
    }
}