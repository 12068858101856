import { useState } from 'react'
import { jwtDecode } from "jwt-decode"
import { useLocalStorage } from '../../../hooks/useLocalStorage'

import { IconButton, Menu, MenuItem, MenuList } from "@mui/material"
import MessageIcon from '@mui/icons-material/Message'

const PredefinedMessagesMenu = ({
    lead,
    onSelected
}) => {
    const [ userStoraged ] = useLocalStorage("user", null)
    const user = jwtDecode(userStoraged.credential)

    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const options = [
        {label: "Mensagem de boas vindas", value: `Olá ${lead.name}! 😊 \n\nMeu nome é ${user.name === "Keeper Tecnologia" ? "João Pedro" : user.name} e irei continuar o seu antendimento. \n\nMe diga, como eu posso te ajudar?`},
        {label: "Agradecimento sem contratação", value: `Agradeço sinceramente pelo seu contato. No entanto, peço desculpas por não poder atendê-lo neste momento.`},
        {label: "Agradecimento com contratação", value: `Gostaríamos de expressar nossa sincera gratidão por escolher nosso aplicativo. Sua preferência é fundamental para nós. Estamos dedicados a proporcionar uma experiência excepcional e estamos à disposição para atendê-lo(a) da melhor forma possível. Muito obrigado por confiar em nós.`},
        {label: "Indicação para troca de pneu de moto", value: "Não trabalhamos com borracharia para motos, mas consigo indicar alguns parcerios:\n\nSomani -> +55 31 9463-3100\nBorracharia Móvel Motos 24h -> +55 31 7547-0320"}
    ]
    
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    
    const handleClose = () => {
        setAnchorEl(null);
    }

    const handleOnSelected = (label) => {
        const optionSelected = options.filter(option => option.label === label)[0]

        onSelected(optionSelected)
        handleClose()
    }

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MessageIcon style={{color: "#009CF5"}}/>
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuList dense>
                    {options.map((item, index) => {
                        return (
                            <MenuItem key={index} onClick={() => handleOnSelected(item.label)}>{item.label}</MenuItem>
                        )
                    })}
                </MenuList>
            </Menu>
        </div>
    );
}

export default PredefinedMessagesMenu