import { useState } from "react"
import { Modal } from "@mui/material"
import { useDispatch, useSelector } from "react-redux"

import * as leadsService from '../../../services/leads'
import * as chatService from '../../../services/chat'

import { openModal } from "../../../action/modal"

import './styles.css'
import InputSelect from "../../Input/Select"
import Button from "../../Button"
import { loadLeads, loadMessages, selectLead } from "../../../action/leads"

const ModalFinishContact = ({device}) => {
    const dispatch = useDispatch()

    const lead = useSelector(state => state.lead.selected)
    const modalActive = useSelector(state => state.modal.finishContact)

    const [reason, setReason] = useState()
    const [loading, setLoading] = useState(false)

    const options = [
        {id: "gaveUpHiring", label: "Desistiu da contratação"},
        {id: "unableHelp", label: "Não conseguimos ajudar"},
        {id: "provider", label: "Prestador"},
        {id: "serviceCompleted", label: "Serviço finalizado"}
    ]

    const handleClose = () => {
        dispatch(openModal({modal: "finishContact", value: false}))
    }

    const submit = async () => {
        setLoading(true)
        const response = await leadsService.finishContact({
            reason: reason,
            uuidLead: lead.uuid
        })

        if (response) {
            const response2 = await leadsService.getLeads()
            if (response2) {
                dispatch(loadLeads(response2))

                const uuidLeads = response2.map(item => item.uuid)
                const response3 = await chatService.loadMessages(uuidLeads)
                if (response3) {
                    dispatch(loadMessages(response3))
                    dispatch(selectLead({}))
                    handleClose()
                }
            }
        }
        setLoading(false)
    }

    return (
        <Modal open={modalActive} onClose={handleClose}>
            <div className="modal-finish-contact-container">
                <div className="modal-finish-contact-header">
                    <h2>Finalizar atendimento</h2>
                </div>
                <div className="modal-finish-contact-body">
                    <span>Selecione o motivo da encerramento:</span>
                    <InputSelect 
                        label="Motivo do encerramento"
                        value={reason}
                        options={options}
                        onChange={(event) => setReason(event.target.value)}
                    />
                </div>
                <div className="modal-finish-contact-footer">
                    <Button 
                        kind="secondary" 
                        label={device !== "mobile" ? "Continuar atendimento":"Continuar"}
                        onClick={handleClose}
                    />
                    <Button 
                        loading={loading}
                        disabled={!reason} 
                        kind="primary" 
                        label={device !== "mobile" ? "Finalizar atendimento":"Finalizar"}
                        onClick={submit}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ModalFinishContact