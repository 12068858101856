import Avatar from '@mui/material/Avatar';

const CustomAvatar = ({
    label, 
    size,
    kind,
    src
}) => {
    if (kind === "photo") {
        return <Avatar alt="Contact" src={src} sx={size && { width: size, height: size }}/>
    }
    return (
        <Avatar sx={size && { width: size, height: size }}>
            {label[0]}
        </Avatar>
    )
}

export default CustomAvatar