import { useEffect, useState } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { jwtDecode } from "jwt-decode"

import * as leadService from '../../../services/leads'
import * as chatService from '../../../services/chat'
import * as solicitationService from '../../../services/solicitations'

import { loadLeads, loadMessages } from "../../../action/leads"
import { useLocalStorage } from "../../../hooks/useLocalStorage"
import { useWebSocket } from "../../../hooks/useWebsocket"
import { loadServices } from "../../../action/services"

import ModalFinishContact from "../../../components/Modal/FinishContact"
import PaperLead from "../../../components/Paper/Leads"
import Layout from "../../../components/Layout"
import Solicitation from "./Solicitation"
import Chat from "./Chat"

import DoneVoctor from '../../../assets/vector/done.svg'

import './styles.css'

const Leads = () => {
    const dispatch = useDispatch()

    const { startWebSocket } = useWebSocket()
    const [ userStoreged ] = useLocalStorage("user", null)

    const leads = useSelector(state => state.lead.list)
    const leadSelected = useSelector(state => state.lead.selected)
    const refreshLeads = useSelector(state => state.lead.refresh)
    const connectionWss = useSelector(state => state.webSocket)

    const [deviceType, setDeviceType] = useState("desktop");

    const getServicesActive = async () => {
        const response = await solicitationService.getServicesActive()
        if (response) dispatch(loadServices(response))
    }

    const getDeviceType = () => {
        const userAgent = navigator.userAgent;
    
        if (/Tablet|iPad/i.test(userAgent)) {
            return "tablet"; // Primeiro verifica tablets para evitar conflitos
        } else if (/Mobile|Android|iP(hone|od)|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
            return "mobile";
        } else {
            return "desktop";
        }
    };

    const renderContact = () => {
        if (deviceType === "mobile" && Object.keys(leadSelected).length > 0) { 
            return 
        } else {
            return (
                <div className="leads-contacts-content">
                    {leads.length > 0 ? (
                        <div>
                            <span className="leads-contacts-title">Leads:</span>
                            <div className="leads-contacts-grid">
                                {leads.map((lead, index) => {
                                    return (
                                        <PaperLead 
                                            key={index} 
                                            lead={lead}
                                            active={leadSelected.uuid === lead.uuid} 
                                        />
                                    )
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="leads-contacts-done">
                            <img src={DoneVoctor} alt="" />
                            <span>Tudo calmo por aqui!</span>
                        </div>
                    )}
                </div>
            )
            
        }
    }

    const renderChat = () => {
        if (deviceType === "mobile" && Object.keys(leadSelected).length === 0) {
            return 
        } else {
            return (
                <div className="leads-chat-content">
                    {Object.keys(leadSelected).length > 0 ? (
                        <div className="leads-chat-grid">
                            <Chat device={deviceType}/>
                            <Solicitation />
                        </div>
                    ) : (
                        <div>
                            selecione uma conversa
                        </div>
                    )}
                </div>
            )
        }
    }

    useEffect(() => {
        if (refreshLeads) {
            (async() => {
                const response = await leadService.getLeads()
                if (response) dispatch(loadLeads(response))
            })()
        }
    }, [refreshLeads])

    useEffect(() => {
        if(!connectionWss.ready) {
            const user = jwtDecode(userStoreged.credential)
            startWebSocket(user.email)
        }
    }, [])

    useEffect(() => {
        const device = getDeviceType() 
        setDeviceType(device)
    }, []);

    useEffect(() => { (async() => { getServicesActive() })() }, [])

    return (
        <>
            <ModalFinishContact device={deviceType}/>
            <Layout>
                <div className="leads-container">
                    {renderContact()}
                    {renderChat()}
                </div>
            </Layout>
        </>
    )
}

export default Leads